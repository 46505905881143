module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<style>\n  .title{\n    text-align: center;\n    font-size: 20pt;\n    font-weight: 500;\n    padding-top: 10pt;\n    margin-top: 10pt;\n  }\n  .info>div{\n    display: flex;\n    line-height: 1;\n  }\n  .info>div>div{\n    flex: 1;\n    margin-top: 5pt;\n    line-height: 1;\n  }\n</style>\n<div>\n  <div class="title">易采惠订货平台销售出库单</div>\n  <div class="info">\n    <div>\n      <div>客户名称：' +
((__t = (customerName )) == null ? '' : __t) +
'</div>\n      <div style="flex: 2">备注说明：' +
((__t = (remark )) == null ? '' : __t) +
'</div>\n    </div>\n    <div>\n      <div>下单时间：' +
((__t = (createdAt )) == null ? '' : __t) +
'</div>\n      <div>收货人：' +
((__t = (consigneeName )) == null ? '' : __t) +
'</div>\n      <div>联系电话：' +
((__t = (consigneeMobile )) == null ? '' : __t) +
'</div>\n    </div>\n    <div>\n      <div>收货地址：' +
((__t = (consigneeAddress )) == null ? '' : __t) +
'</div>\n    </div>\n  </div>\n</div>';

}
return __p
}