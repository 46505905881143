module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!DOCTYPE html>\n  <html lang="en">\n  <head>\n    <meta charset="utf-8">\n    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n    <meta http-equiv="X-UA-Compatible" content="ie=edge">\n    <title></title>\n    ' +
((__t = ( require('./template/saleStyle.ejs')() )) == null ? '' : __t) +
'\n  </head>\n  <body onafterprint="afterPrint()">\n    ';
 var info=list[0] || {} ;
__p += '\n    ' +
((__t = ( require('./template/saleInfo.ejs')({
      customerName: info.customerName,
      id: list.length > 1 ? '合并订单' : info.saleId,
      remark: info.remark,
      createdAt: info.createdAt,
      consigneeName: info.consigneeName,
      consigneeMobile: info.consigneeMobile,
      consigneeAddress: info.consigneeAddress
    }) )) == null ? '' : __t) +
'\n\n    ' +
((__t = ( require('./template/saleTable.ejs')({
      products: list.map(item => item.detailList).flat(),
      totalCount: list.reduce((c, n) => c + parseFloat(n.totalCount), 0),
      totalAmount: list.reduce((c, n) => c + parseFloat(n.totalAmount), 0),
      payAmount: list.reduce((c, n) => c + parseFloat(n.payAmount), 0)
    }) )) == null ? '' : __t) +
'\n\n    ' +
((__t = ( console.log(list.map(item => item.products).flat()))) == null ? '' : __t) +
'\n\n    <div style="page-break-after:always">\n      ' +
((__t = ( require('./template/saleFooter.ejs')() )) == null ? '' : __t) +
'\n    </div> \n    <script>\n      function afterPrint() {\n        window.parent.afterPrint(self.frameElement);\n      }\n    </script>\n  </body>\n</html>\n';

}
return __p
}