module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<!DOCTYPE html>\n  <html lang="en">\n  <head>\n    <meta charset="utf-8">\n    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n    <meta http-equiv="X-UA-Compatible" content="ie=edge">\n    <title></title>\n    ' +
((__t = ( require('./template/saleStyle.ejs')() )) == null ? '' : __t) +
'\n    <script>\n      function afterPrint() {\n        window.parent.afterPrint(self.frameElement);\n      }\n    </script>\n  </head>\n  <body onafterprint="afterPrint()">\n    ';
 list.forEach(function(item){ ;
__p += '\n      \n      ' +
((__t = ( require('./template/saleInfo.ejs')({
        customerName: item.customerName,
        id: item.saleId,
        remark: item.remark,
        createdAt: item.createdAt,
        consigneeName: item.consigneeName,
        consigneeMobile: item.consigneeMobile,
        consigneeAddress: item.consigneeAddress
      }) )) == null ? '' : __t) +
'\n\n      ' +
((__t = ( require('./template/saleTable.ejs')({
        products: item.detailList,
        totalCount: item.totalCount,
        totalAmount: item.totalAmount,
        payAmount: item.payAmount
      }) )) == null ? '' : __t) +
'\n      \n      <div style="page-break-after:always">\n        ' +
((__t = ( require('./template/saleFooter.ejs')() )) == null ? '' : __t) +
'\n      </div>\n    ';
 }); ;
__p += '\n  </body>\n</html>\n';

}
return __p
}