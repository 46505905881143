import COM_ENUM from "../enum";

export default class LibSaleOrderFunction {
  // 根据规格组合商品
  static composeProductBySku(products: {skuId: string; orderCount: number}[]) {
    const skuObject = {};
    products.forEach(item => {
      if (skuObject[item.skuId]) {
        skuObject[item.skuId].count = Number(item.orderCount) + Number(skuObject[item.skuId].count);
      } else {
        skuObject[item.skuId] = item;
      }
    });

    return Object.values(skuObject);
  }

  // 是否代下单
  static isAgentCreate(orderWay: string) {
    return orderWay === COM_ENUM.SALE_ORDER_WAY.AGENT.value;
  }

  // 是否自主下单
  static isCustomerCreate(orderWay: string) {
    return orderWay === COM_ENUM.SALE_ORDER_WAY.CUSTOMER.value;
  }

  // 是否待审核
  static isWaitAudit(orderStatus: string) {
    return orderStatus === COM_ENUM.SALE_ORDER_STATUS.WAIT_AUDIT.value;
  }

  // 是否待出库
  static isWaitOutStock(orderStatus: string) {
    return orderStatus === COM_ENUM.SALE_ORDER_STATUS.WAIT_OUT_STOCK.value;
  }

  // 是否待发货
  static isWaitDeliver(orderStatus: string) {
    return orderStatus === COM_ENUM.SALE_ORDER_STATUS.WAIT_DELIVER.value;
  }

  // 是否完成
  static isDone(orderStatus: string) {
    return orderStatus === COM_ENUM.SALE_ORDER_STATUS.FINISHED.value;
  }

  // 是否作废
  static isInvalid(orderStatus: string) {
    return orderStatus === COM_ENUM.SALE_ORDER_STATUS.INVALID.value;
  }

  // 是否付款
  static isPayed(paymentStatus: string) {
    return paymentStatus === COM_ENUM.PAYMENT_STATUS.FINISHED.value;
  }

  // 出库验证，并且过滤出库数量为0的选项
  static async outStockValidate(data: {
    saleDetailId: string;
    skuId: string;
    skuName: string;
    count: number;
    items: {
      stockId: string;
      count?: number;
    }[]
  }[]) {
    if (!Array.isArray(data)) {
      throw new Error('出库信息错误');
    }
    const result: {
      saleDetailId: string;
      items: {
        stockId: string;
        count: number;
      }[]
    }[] = [];

    for (let sku of data) {
      if (Array.isArray(!sku.items)) {
        throw new Error('出库信息错误');
      }

      sku.items = sku.items.filter(item => {
        return !isNaN(Number(item.count)) && Number(item.count) > 0;
      });

      const totalOutCount = sku.items.reduce((pre, cur) => {
        return +cur.count + pre;
      }, 0);

      if (totalOutCount !== sku.count) {
        throw new Error(`“${sku.skuName}” 出库数量与订单数量不一致`);
      }

      result.push({
        saleDetailId: sku.saleDetailId,
        items: sku.items.map(item => {
          return {
            stockId: item.stockId,
            count: item.count,
          };
        }),
      });
    }
    return Promise.resolve(result);
  }
}