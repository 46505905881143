import React from 'react';
import { Dropdown, Menu } from 'antd';
import { Button } from '@/components';

const orderPrintCombine = require('./orderPrintCombine.ejs');
const orderBatch = require('./orderPrintBatch.ejs');
const orderOutStock = require('./orderOutStock.ejs');
const orderDelivery = require('./orderDelivery.ejs');
const orderSign = require('./orderSign.ejs');

export enum Status {
  Unfinished = '11',
  Finished = '12'
}

export default props => {
  const { status, clickPrint = () => {}, clickPrintStatus = () => {} } = props;

  return (
    <Dropdown
      overlayClassName="drop-button"
      overlay={
        <Menu>
          <Menu.Item key="1" onClick={clickPrint}>
            打印销售出库单
          </Menu.Item>
          {status && <Menu.Divider />}
          {!status || status === Status.Finished ? <Menu.Item onClick={() => clickPrintStatus('11')}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <i className="iconfont icon-weiwancheng" style={{marginRight: 2, fontSize: 16, color: '#faad14', lineHeight: 1}}></i>
              标为未打印
            </div>
          </Menu.Item> : null}
          {!status || status === Status.Unfinished ? <Menu.Item onClick={() => clickPrintStatus('12')}>
            <div style={{
              display: 'flex',
              alignItems: 'center'
            }}>
              <i className="iconfont icon-success" style={{marginRight: 2, fontSize: 16, color: '#52c41a'}}></i>
              标为已打印
            </div>
          </Menu.Item> : null}
        </Menu>
      }
    >
      <Button type="default" icon={<i className="iconfont icon-printing"></i>}>
        {status === Status.Finished ? '已打印' : '打印'} <i style={{color: '#333', marginLeft: 3}} className="iconfont icon-arrow-down"></i>
      </Button>
    </Dropdown>
  );
}
export const print = (list: any[] = [], afterPrint = () => {}) => {

  window['afterPrint'] = frame => {
    afterPrint();
    document.body.removeChild(frame);
  };
  const iframe = document.createElement('IFRAME');
  let doc: Document;
  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');

  document.body.appendChild(iframe);
  doc = iframe['contentWindow'].document;
  // 引入打印的专有CSS样式，根据实际修改
  // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');

  const toDecimal = (x) => {    
    var f = parseFloat(x);    
    if (isNaN(f)) {    
        return false;    
    }    
    var f = Math.round(x*100)/100;    
    var s = f.toString();    
    var rs = s.indexOf('.');    
    if (rs < 0) {    
        rs = s.length;    
        s += '.';    
    }    
    while (s.length <= rs + 2) {    
        s += '0';    
    }    
    return s;    
  };

  const html = orderPrintCombine({ list, toDecimal });
  doc?.write(html);
  doc?.close();
  // 获取iframe的焦点，从iframe开始打印
  iframe['contentWindow'].focus();
  iframe['contentWindow'].print();
};

export const printBatch = (list: any[] = [], afterPrint = () => {}) => {
  window['afterPrint'] = frame => {
    afterPrint();
    document.body.removeChild(frame);
  };
  const iframe = document.createElement('IFRAME');
  let doc: Document;
  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');

  document.body.appendChild(iframe);
  doc = iframe['contentWindow'].document;
  // 引入打印的专有CSS样式，根据实际修改
  // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');
  const html = orderBatch({ list });
  doc.write(html);
  doc.close();
  // 获取iframe的焦点，从iframe开始打印
  iframe['contentWindow'].focus();
  iframe['contentWindow'].print();
};

export const printOutStock = (data, after = () => {}) => {
  window['afterPrint'] = frame => {
    after();
    document.body.removeChild(frame);
  };
  const iframe = document.createElement('IFRAME');
  let doc: Document;
  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');

  document.body.appendChild(iframe);
  doc = iframe['contentWindow'].document;
  // 引入打印的专有CSS样式，根据实际修改
  // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');
  const html = orderOutStock({ ...data });
  doc.write(html);
  doc.close();
  // 获取iframe的焦点，从iframe开始打印
  iframe['contentWindow'].focus();
  iframe['contentWindow'].print();
};

export const printDelivery = (data, after = () => {}) => {
  window['afterPrint'] = frame => {
    after();
    document.body.removeChild(frame);
  };
  const iframe = document.createElement('IFRAME');
  let doc: Document;
  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');

  document.body.appendChild(iframe);
  doc = iframe['contentWindow'].document;
  // 引入打印的专有CSS样式，根据实际修改
  // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');

  const html = orderDelivery({ ...data });
  doc.write(html);
  doc.close();
  // 获取iframe的焦点，从iframe开始打印
  iframe['contentWindow'].focus();
  iframe['contentWindow'].print();
};

export const printSign = (data, after = () => {}) => {
  window['afterPrint'] = frame => {
    after();
    document.body.removeChild(frame);
  };
  const iframe = document.createElement('IFRAME');
  let doc: Document;
  iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');

  document.body.appendChild(iframe);
  doc = iframe['contentWindow'].document;
  // 引入打印的专有CSS样式，根据实际修改
  // doc.write('<LINK rel="stylesheet" type="text/css" href="css/print.css">');

  const html = orderSign({ ...data });
  doc.write(html);
  doc.close();
  // 获取iframe的焦点，从iframe开始打印
  iframe['contentWindow'].focus();
  iframe['contentWindow'].print();
};
