module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<html><head>\n  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n  <style type="text/css">\n  table {page-break-after:always;}\n   .b1{text-align:center;white-space-collapsing:preserve;-webkit-print-color-adjust: exact;}\n  .t1{border-collapse:collapse;border-spacing:0;}\n  .r1{height:37.5pt;}\n  .r2{height:25.5pt;}\n  .r3{height:40.5pt;}\n  .c1{white-space:pre-wrap;text-align:center;background-color:silver;border-top:thin solid;border-right:thin solid;border-bottom:thin solid;border-left:thin solid;font-weight:bold;color: black; font-size:20pt;}\n  .c2{white-space:pre-wrap;text-align:center;border-top:thin solid;border-right:thin solid;border-bottom:thin solid;border-left:thin solid;color: black; font-size:11pt;}\n  .c3{white-space:pre-wrap;text-align:center;border-top:thin solid;border-bottom:thin solid;border-left:thin solid;color: black; font-size:11pt;}\n  .c4{white-space:pre-wrap;text-align:center;border-top:thin solid;border-bottom:thin solid;color: black; font-size:11pt;}\n  .c5{white-space:pre-wrap;text-align:center;background-color:silver;border-top:thin solid;border-right:thin solid;border-bottom:thin solid;border-left:thin solid;color: black; font-size:18pt;}\n  .c6{white-space:pre-wrap;border-top:thin solid;border-right:thin solid;border-bottom:thin solid;border-left:thin solid;color: black; font-size:11pt;}\n  .c7{white-space:pre-wrap;text-align:center;color: black; font-size:11pt;}\n  </style>\n  </head>\n  <body class="b1">\n\n  <table class="t1">\n  <colgroup>\n  <col width="79">\n  <col width="160">\n  <col width="148">\n  <col width="209">\n  <col width="83">\n  <col width="67">\n  <col width="68">\n  <col width="87">\n  </colgroup>\n  <tbody>\n  <tr class="r1">\n    <td class="c1" colspan="8">出 库 单</td>\n  </tr>\n  <tr class="r2">\n    <td class="c2">出库编号</td>\n    <td class="c2">' +
((__t = (id )) == null ? '' : __t) +
'</td>\n    <td class="c2">出库日期</td>\n    <td class="c2">' +
((__t = (createTime )) == null ? '' : __t) +
'</td>\n    <td class="c3">出库仓库</td>\n    <td class="c2">' +
((__t = (repoName )) == null ? '' : __t) +
'</td>\n    <td class="c4">预交货日期</td>\n    <td class="c2">' +
((__t = (expectTime )) == null ? '' : __t) +
'</td>\n  </tr>\n  <tr class="r2">\n    <td class="c2">客户</td>\n    <td class="c2" colspan="3">' +
((__t = (userName )) == null ? '' : __t) +
'</td>\n    <td class="c3" colspan="2">联系人</td>\n    <td class="c2" colspan="2">' +
((__t = (consigneeName )) == null ? '' : __t) +
'</td>\n  </tr>\n  <tr class="r2">\n    <td class="c2">收货地址</td>\n    <td class="c2" colspan="3">' +
((__t = (consigneeAddress )) == null ? '' : __t) +
'</td>\n    <td class="c3" colspan="2">联系电话</td>\n    <td class="c2" colspan="2">' +
((__t = (consigneeMobile )) == null ? '' : __t) +
'</td>\n  </tr>\n  <tr class="r3">\n    <td class="c5" colspan="8">商 品 明 细</td>\n  </tr>\n  <tr class="r2">\n    <td class="c2">序号</td>\n    <td class="c2">商品编号</td>\n    <td class="c2">商品名称</td>\n    <td class="c2">商品规格</td>\n    <td class="c2">单价(元)</td>\n    <td class="c2">数量</td>\n    <td class="c2">计量单位</td>\n    <td class="c2">备注</td>\n  </tr>\n  ';
 details.forEach(function(item, index) { ;
__p += '\n    <tr class="r2">\n      <td class="c6" style="text-align: center;">' +
((__t = (index+1 )) == null ? '' : __t) +
'</td>\n      <td class="c2">' +
((__t = (item.productId )) == null ? '' : __t) +
'</td>\n      <td class="c2">' +
((__t = (item.productName )) == null ? '' : __t) +
'</td>\n      <td class="c2">' +
((__t = (item.skuName )) == null ? '' : __t) +
'</td>\n      <td class="c6" style="text-align: center;">' +
((__t = (item.price )) == null ? '' : __t) +
'</td>\n      <td class="c6" style="text-align: center;">' +
((__t = (item.countOrder )) == null ? '' : __t) +
'</td>\n      <td class="c2">' +
((__t = (item.unitOrderName )) == null ? '' : __t) +
'</td>\n      <td class="c2"></td>\n    </tr>\n  ';
 }); ;
__p += '\n  <tr class="r2">\n    <td class="c2">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n  </tr>\n  <tr class="r2">\n    <td class="c2">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c2">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n  </tr>\n  <tr class="r2">\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n    <td class="c6">&nbsp;</td>\n  </tr>\n  <tr class="r2">\n    <td></td>\n    <td></td>\n    <td></td>\n    <td></td>\n    <td class="c7">&nbsp;</td>\n    <td class="c7">&nbsp;</td>\n    <td class="c7">&nbsp;</td>\n  </tr>\n  </tbody>\n  </table>\n\n  </body></html>\n';

}
return __p
}