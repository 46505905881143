module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<style>\n  table{\n    width: 100%;\n    margin-top: 10pt;\n    border-collapse: collapse;\n    border-spacing: 0;\n    box-sizing: border-box;\n  }\n  table tr{\n    height: 25pt;\n    vertical-align: middle;\n  }\n  .left{\n    padding-left: 5pt;\n  }\n  .center{\n    text-align: center;\n  }\n  .right{\n    text-align: right;\n  }\n</style>\n<table border="1">\n  <tr class="head">\n    <td class="center" width="60">序号</td>\n    <td class="left">商品名称</td>\n  <td class="left" width="100">规格</td>\n    <!-- <td class="center">订单编号</td> -->\n    <td class="left" width="80">计量单位</td>\n    <td class="left" width="60">数量</td>\n    <td class="left" width="60">单价</td>\n    <td class="left" width="80">金额小计</td>\n    <td class="left" width="100">备注</td>\n  </tr>\n  ';
 products.forEach(function(pro, index){ ;
__p += '\n    <tr>\n      <td class="center">' +
((__t = (++index )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.productName )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.skuName )) == null ? '' : __t) +
'</td>\n      <!-- <td class="left">' +
((__t = (pro.saleDetailId )) == null ? '' : __t) +
'</td> -->\n      <td class="left">' +
((__t = (pro.orderUnitName )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.orderCount )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.price )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.payAmount )) == null ? '' : __t) +
'</td>\n      <td class="left">' +
((__t = (pro.remark )) == null ? '' : __t) +
'</td>\n    </tr>\n  ';
 }); ;
__p += '\n  <tr>\n    <!-- <td class="center">合计</td>\n    <td class="left"></td>\n    <td class="center"></td>\n    <td class="left"></td>\n    <td class="center">\n      ' +
((__t = ( totalCount )) == null ? '' : __t) +
'\n    </td>\n    <td class="left"></td>\n    <td class="center">\n      ' +
((__t = ( payAmount )) == null ? '' : __t) +
'\n    </td>\n    <td class="left"></td> -->\n    <td class="right" colspan="8">\n      <!-- 商品金额：' +
((__t = ( totalAmount )) == null ? '' : __t) +
'\n      &nbsp;&nbsp; -->\n      数量：' +
((__t = ( totalCount )) == null ? '' : __t) +
'\n      &nbsp;&nbsp;\n      应付金额：' +
((__t = ( payAmount )) == null ? '' : __t) +
'\n      &nbsp;&nbsp;\n    </td>\n  </tr>\n</table>';

}
return __p
}